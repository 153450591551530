import React from 'react'
import bg from '../images/bg.webp'
import bg2 from '../images/mob_bg.webp'
import img1 from '../images/glim1.webp'
import img2 from '../images/glim2.webp'
import img3 from '../images/glim3.webp'
import img4 from '../images/glim4.webp'
import img5 from '../images/glim5.webp'
import mob1 from '../images/glim_mob1.webp'
import mob2 from '../images/glim_mob2.webp'
import mob3 from '../images/glim_mob3.webp'
import mob4 from '../images/glim_mob4.webp'
import mob5 from '../images/glim_mob5.webp'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';

const Glimpse = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className={`glimpse-section position-relative ${isMobile ? 'section-padding' : 'py-6'}`}>
      <img src={bg} alt="" className='img-fluid position-absolute top-0 start-0'></img>
      <Container className='position-relative'>
        {isMobile ?
          <>
            <div><h1 className='text-capitalize'>A glimpse</h1><h1> of IRA</h1><h1>M3</h1></div>
            <Row>
              <Col className='d-flex flex-column-reverse'><img src={mob3} alt="" className='img-fluid mb-3'></img></Col>
              <Col><img src={mob5} alt="" className='img-fluid mb-3'></img></Col>
            </Row>
            <Row>
              <Col><img src={mob1} alt="" className='img-fluid mb-3'></img></Col>
              <Col>
              <img src={mob4} alt="" className='img-fluid mb-3'></img>
              <img src={mob2} alt="" className='img-fluid mb-3'></img>
              </Col>
            </Row>
          </>
          :
          <Row>
            <Col lg={5}>
              <div><h1 className='text-capitalize'>A glimpse</h1><h1> of IRA</h1><h1>M3</h1>
              </div>
              <div><img src={img1} alt="" className='img-fluid'></img></div>
            </Col>
            <Col lg={3}>
              <div><img src={img3} alt="" className='img-fluid mb-3'></img></div>
              <div><img src={img4} alt="" className='img-fluid'></img></div>
            </Col>
            <Col lg={4}>
              <div><img src={img2} alt="" className='img-fluid mb-3'></img></div>
              <div><img src={img5} alt="" className='img-fluid'></img></div>
            </Col>
          </Row>
        }

      </Container>
    </div>
  )
}

export default Glimpse
