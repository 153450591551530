import React, {useState} from 'react'
import { Row, Col, Form, Spinner } from 'react-bootstrap'
import useFormvalidation from '../validations/FormValidation' 
import { ContactFormRules } from "../validations/FormValidationRules";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useMediaQuery } from 'react-responsive';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from"moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegCalendar } from "react-icons/fa";
import Apis from '../services/Apis'

const ContactForm = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const {saveContactFormdata} = Apis()
    const [date, setDate] = useState(null);
    const [loader, setLoader] = useState(false)
    const [formValues, setFormValues] = useState(
        {
          "name": "",
          "phone": "+91",
          "email": "",
          "message":"",
          "property": "",
        }
    );

    const notify = (msz) => {
        toast(msz)
    }

    const handleNext = async() => {
 
        var form_data = new FormData();
        for (var key in values) {
            form_data.append(key, values[key]);
        }
        const response = await saveContactFormdata(form_data);
        setLoader(true)
        if (response.status === 200) {
            setLoader(false)
            notify(response.data)
            setValues(
                {
                  "name": "",
                  "phone": "",
                  "email": "",
                  "subject": "",
                  "property": "",
                }
            )
            setDate(null)
        }
    }

    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        ContactFormRules,
        formValues
    );

    const handleDate = (data) => {
        const formattedDate = moment(data).format('MM/DD/YYYY'); // Format the selected date
        setValues(values => ({ ...values, message: "Schedule Date is : "+ formattedDate , property: "M3"}));
        setDate(formattedDate)
    }

  return (
    <>
    <Row className={`${!isMobile ? 'mb-4' : ''}`} style={{ paddingRight: isMobile ? '' : '' }}>
                <Col lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                    <Form.Control type="text" placeholder="Name" name="name" onChange={handleChange} value={values.name} autoComplete="new-item" />
                    <span className='error mt-3'>{errors.hasOwnProperty('name') &&
                        <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.name} </>}
                    </span>
                </Col>
                <Col lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                    <Form.Control type="email" placeholder="Email" name="email" onChange={handleChange} value={values.email} autoComplete="new-item" />
                    <span className='error mt-3'>{errors.hasOwnProperty('email') &&
                        <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.email} </>}
                    </span>
                </Col>
            </Row>
            <Row style={{ paddingRight: isMobile ? '' : '' }}>
                <Col lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                    <Form.Control type="text" placeholder="Phone Number" name="phone" onChange={handleChange} value={values.phone} autoComplete="new-item" />
                    <span className='error mt-3'>{errors.hasOwnProperty('phone') &&
                        <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.phone} </>}
                    </span>
                </Col>
                <Col lg={6} md={12} xs={12}>
                    <div className='datepicker-container'>
                        <DatePicker selected={date ? new Date(date) : null} onChange={handleDate} className='custom-datepicker' placeholderText="Select Date" />
                        <FaRegCalendar className="calendar-icon" />
                    </div>
                    <span className='error mt-3'>{errors.hasOwnProperty('message') &&
                        <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.message} </>}
                    </span>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <label className="form-check-label authorize-label" >
                    We promise no spam, only great home advice!
                    </label>
                </Col>
            </Row>
            <Row className={`${isMobile ? 'mt-3' : 'mt-3'}`}>
                <Col>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label authorize-label" htmlFor="flexCheckChecked">
                            I authorize representatives of IRA Aspiration to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DNC/NDNC.
                        </label>
                    </div>
                </Col>
            </Row>
            <button type="submit" className='primary-background text-white common-btn mt-3 border-0' onClick={handleSubmit}>Book a Visit{loader && <Spinner className='loader' animation="border"  />}</button>
            <ToastContainer />
    </>
  )
}

export default ContactForm
