import React from 'react'
import logo from '../images/ira_logo.webp'
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch, useSelector } from 'react-redux';
import ContactModal from './ContactModal';
import { Link } from 'react-scroll';

const Header = () => {
    const dispatch = useDispatch()
    const toggle = useSelector((state) => state.toggle);
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="#"><img src={logo} alt="logo"></img></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" smooth={true} duration={1000}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="facing" smooth={true} duration={1000}>Floor Plans</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="amenities" smooth={true} duration={1000}>Amenities</Link>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => dispatch(setToggleTrue())}>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {
                toggle.hastoggle && <ContactModal />
            }
        </>
    )
}

export default Header
