import React, { Suspense } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop'
import Home from './views/Home'
import * as ROUTES from './constant/routes'

function App() {
  return (
    <Router>
      <Suspense>
            <Routes>
                <Route path={ROUTES.Home} element={
                    <ScrollToTop>
                            <Home />
                    </ScrollToTop>
                } />
            </Routes>
        </Suspense>
    </Router>
  );
}

export default App;
