import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import img1 from '../images/smart1.webp'
import img2 from '../images/smart2.webp'
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch, useSelector } from 'react-redux';

const Smart = () => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className={`smart-section ${isMobile ? 'section-padding' : 'py-6'}`}>
            <Container>
                <div className={`text-center ${isMobile ? 'mb-4' : 'mb-5'} `}>
                    <h2 className='text-capitalize primary-color mb-4'>Smart Homes, Smarter Living!</h2>

                    <p>M3’s fully furnished, smart home-automated apartments bring convenience and coolness together. Our unique smart furniture concept allows you to control your home with ease, combining modern tech with stylish living.
                        Control your lights, appliances, and security with just your voice or phone. And hey, did we mention the swanky interiors are ready when you are?
                    </p>


                </div>
                <Row>
                    <Col lg={6} className={`${isMobile ? 'mb-4' : ''}`}>
                        <img src={img1} alt="smart1" className='img-fluid'></img>
                    </Col>
                    <Col lg={6}>
                        <img src={img2} alt="smart2" className='img-fluid'></img>
                    </Col>
                </Row>
                <div className='text-center mt-5'>
                <button type="submit" className='primary-background text-white common-btn border-0' onClick={() => dispatch(setToggleTrue())}>Book Now </button>
                </div>
            </Container>
        </div>
    )
}

export default Smart
