import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../images/icon1.webp'
import img2 from '../images/icon2.webp'
import img3 from '../images/icon3.webp'
import img4 from '../images/icon4.webp'
import img5 from '../images/icon5.webp'
import img6 from '../images/icon6.webp'
import { useMediaQuery } from 'react-responsive';

const Amenities = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className={`amenities-section ${isMobile ? 'section-padding' : 'py-6'}`} id="amenities">
            <Container>
                <div className='text-center mb-5'>
                    <h2 className='text-capitalize primary-color mb-4'>Live Your Best Life with World Class Amenities</h2>
                    <h6>IRA M3 offers a range of amenities to ensure that you and your family can enjoy the best lifestyle, right at your doorstep.</h6>
                </div>
                <Row className='mb-3'>
                    <Col lg={4} className={`${isMobile ? 'mb-3' : '' }`}>
                        <div className='d-flex align-items-center'>
                            <div className='icon'><img src={img1} alt="icon1" className='img-fluid'></img></div>
                            <div className='icon-text'><p>Clubhouse with gym, lounge and indoor games</p></div>
                        </div>
                    </Col>
                    <Col lg={4} className={`${isMobile ? 'mb-3' : '' }`}>
                        <div className='d-flex align-items-center'>
                            <div className='icon'><img src={img2} alt="icon2" className='img-fluid'></img></div>
                            <div className='icon-text'><p>Swimming pool and children’s play area</p></div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='d-flex align-items-center'>
                            <div className='icon'><img src={img3} alt="icon3" className='img-fluid'></img></div>
                            <div className='icon-text'><p>Landscaped gardens and jogging tracks</p></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className={`${isMobile ? 'mb-3' : '' }`}>
                        <div className='d-flex align-items-center'>
                            <div className='icon'><img src={img4} alt="icon4" className='img-fluid'></img></div>
                            <div className='icon-text'><p>24/7 security and CCTV surveillance</p></div>
                        </div>
                    </Col>
                    <Col lg={4} className={`${isMobile ? 'mb-3' : '' }`}>
                        <div className='d-flex align-items-center'>
                            <div className='icon'><img src={img5} alt="icon5" className='img-fluid'></img></div>
                            <div className='icon-text'><p>Ample parking for residents and visitors</p></div>
                        </div>
                    </Col>
                    <Col lg={4} className={`${isMobile ? 'mb-3' : '' }`}>
                        <div className='d-flex align-items-center'>
                            <div className='icon'><img src={img6} alt="icon6" className='img-fluid'></img></div>
                            <div className='icon-text'><p>Smart home automation for added convenience</p></div>
                        </div>
                    </Col>
                </Row>
                <div className='grated-div mt-5'>
                    <p>Our gated community comes with a fully equipped clubhouse where you can relax, socialize, and stay active. From the state-of-the-art gym to landscaped gardens, IRA M3 has something for every member of your family.</p>
                </div>
                <div className='text-center mt-5'>
                <button type="submit" className='primary-background text-white common-btn mt-3 border-0 mb-4' >View All Amenities</button>
                </div>
            </Container>
        </div>
    )
}

export default Amenities
