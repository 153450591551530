import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import bg from '../images/project_highlight.webp'
import bg2 from '../images/project_highlight_mob.webp'
import mob_bg from '../images/mob_bg.webp'
import desktop_bg from '../images/bg.webp'
import img1 from '../images/project_highlight1.webp'
import img2 from '../images/project_highlight2.webp'
import img3 from '../images/project_highlight3.webp'
import img4 from '../images/project_highlight4.webp'
import img5 from '../images/mob_project1.webp'
import img6 from '../images/mob_project2.webp'
import img7 from '../images/mob_project3.webp'
import img8 from '../images/mob_project4.webp'
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch, useSelector } from 'react-redux';

const ProjectHighlight = () => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const isSmallMobile = useMediaQuery({ query: '(max-width: 425px' })
    return (
        <div className={`project-highlight position-relative ${isMobile ? 'section-padding' : 'py-6'}`}>
            <img className='position-absolute start-0 top-0' src={isMobile ? mob_bg : desktop_bg} alt=""></img>
            <Container className='position-relative' style={{zIndex: '100'}}>
                
                <div className={`text-center ${isMobile ? 'mb-5' : ''}`}>
                    {isMobile ?
                        isSmallMobile ?
                            <>
                                <h2 className='text-capitalize primary-color'>Because life’s too</h2>
                                <h2 className='text-capitalize primary-color'>short for boring</h2>
                                <h2 className='text-capitalize primary-color'>homes!</h2>
                            </> :
                            <h2 className={`text-capitalize primary-color`}>Because life’s too short for boring homes!</h2>
                        :
                        <>
                            <h2 className={`text-capitalize primary-color`}>Because life’s too short for</h2>
                            <h2 className={`text-capitalize primary-color ${isMobile ? 'mb-3' : 'mb-4'} `}>boring homes!</h2>
                        </>
                    }

                </div>
                {
                    isMobile ?
                        <>
                            <Row className='mx-1'>
                                <Col>
                                    <div className={`mb-3`}>
                                        <p >Project Area:</p>
                                        <h6 className='primary-color'>5.80 acres</h6>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='mb-3'>
                                        <p>Unit Type:</p>
                                        <h6 className='primary-color'>2.5 BHK</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mx-1'>
                                <Col>
                                    <div className={`mb-3`}>
                                        <p>Apartment Area:</p>
                                        <h6 className='primary-color'>1105 Sft per unit</h6>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='mb-3'>
                                        <p>Price:</p>
                                        <h6 className='primary-color'>Starting 60 L INR*</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mx-1'>
                                <Col>
                                    <div className={`mb-3`}>
                                        <p >Structure:</p>
                                        <h6 className='primary-color'>Ground + 13 floors</h6>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='mb-3'>
                                        <p >Total Units:</p>
                                        <h6 className='primary-color'>695 apartments</h6>
                                    </div>
                                </Col>
                            </Row>
                            <div className='text-center position-relative'>
                            <button type="submit" className='primary-background text-white common-btn border-0 mb-4 ' onClick={() => dispatch(setToggleTrue())}>Join the Fun! </button>
                            </div>
                            <Row className='mb-3 position-relative'>
                                <Col>
                                    <img src={img5} alt="" className='img-fluid'></img>
                                </Col>
                                <Col>
                                <img src={img6} alt="" className='img-fluid'></img>
                                </Col>
                            </Row>
                            <Row className='position-relative'>
                                <Col>
                                    <img src={img7} alt="" className='img-fluid'></img>
                                </Col>
                                <Col>
                                <img src={img8} alt="" className='img-fluid'></img>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row className='position-relative'>
                                <Col lg={3}>
                                    <div className={`${isMobile ? 'mb-3' : 'mb-4'}`}>
                                        <p >Project Area:</p>
                                        <h6 className='primary-color'>5.80 acres</h6>
                                    </div>
                                    <div className={`${isMobile ? 'mb-3' : 'mb-4'}`}>
                                        <p>Apartment Area:</p>
                                        <h6 className='primary-color'>1105 Sft per unit</h6>
                                    </div>
                                </Col>
                                <Col lg={6} className='text-center'>
                                    <button type="submit" className='primary-background text-white common-btn border-0 mb-4'  onClick={() => dispatch(setToggleTrue())}>Join the Fun! </button>

                                </Col>
                                <Col lg={3}>
                                    <div className={`${isMobile ? 'mb-3' : 'mb-4'}`}>
                                        <p>Unit Type:</p>
                                        <h6 className='primary-color'>2.5 BHK</h6>
                                    </div>
                                    <div className={`${isMobile ? 'mb-3' : 'mb-4'}`}>
                                        <p>Price:</p>
                                        <h6 className='primary-color'>Starting 60 L INR*</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='position-relative'>
                                <Col lg={3}>
                                    <img src={img1} alt="img1" className='img-fluid'></img>
                                </Col>
                                <Col lg={3}>
                                    <div className={`${isMobile ? 'mb-3' : 'mb-4'}`}>
                                        <p >Structure:</p>
                                        <h6 className='primary-color'>Ground + 13 floors</h6>
                                    </div>
                                    <img src={img2} alt="img2" className='img-fluid'></img>
                                </Col>
                                <Col lg={3}>
                                    <div className={`${isMobile ? 'mb-3' : 'mb-4'}`}>
                                        <p >Total Units:</p>
                                        <h6 className='primary-color'>695 apartments</h6>
                                    </div>
                                    <img src={img3} alt="img3" className='img-fluid'></img>
                                </Col>
                                <Col lg={3}><img src={img4} alt="img4" className='img-fluid'></img></Col>
                            </Row>
                            
                        </>
                }

            </Container>
            {
                isMobile ?
                <img src={bg2} alt="" className='img-fluid position-absolute start-0 bottom-0'></img>
                :
                <img src={bg} alt="" className='img-fluid position-absolute end-0' style={{top: '21%'}}></img>
            }
        </div>
    )
}

export default ProjectHighlight
