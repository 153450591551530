import React from 'react'
import { Modal, Button, Row, Col, Form, Spinner } from 'react-bootstrap'
import ContactForm from './ContactForm'
import { setToggleFalse } from '../redux/slice/changeState'
import { useSelector, useDispatch } from 'react-redux';


const ContactModal = () => {
    const dispatch = useDispatch()
    const toggle = useSelector((state) => state.toggle);
     

    const handleClose = () => {
        dispatch(setToggleFalse())
    }
    return (
        <Modal show={toggle.hastoggle} onHide={handleClose} centered className='contact-form-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactForm />
            </Modal.Body>
        </Modal>
    )
}

export default ContactModal
