import React from 'react'
import * as common from '../components'

const Home = () => {
    return (
        <>
            <common.Header />
            <common.Banner />
            <common.ProjectHighlight />
            <common.Smart />
            <common.Location />
            <common.Amenities />
            <common.Facing />
            <common.Ikea /> 
            <common.Glimpse />
            <common.Contact />
            <common.Footer />
        </>
    )
}

export default Home
