import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import bg from '../images/banner.webp'
import mob_bg from '../images/mob_banner.webp'
import { useMediaQuery } from 'react-responsive';
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch} from 'react-redux';

const Banner = () => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const isSmallMobile = useMediaQuery({ query: '(max-width: 425px' })
    return (
        <div className={`banner-section position-relative ${isMobile ? 'mx-3' : 'mx-4'}`}>
            <img src={isMobile ? mob_bg : bg} className='img-fluid w-100' alt="miracle"></img>
            <Container >
                <div className='banner-title'>
                    <h1 className='text-white'>M3</h1>
                    <div className='title text-white'>
                        {isMobile
                            ?
                            isSmallMobile ?
                                <>
                                    <h1>Introducing M3,</h1>
                                    <span>Your First Step</span><br />
                                    <span>to Fun Living in</span><br />
                                    <span>Kollur!</span>
                                    <h6 className='mt-3'>Fully furnished, smart home-automated</h6>
                                    <h6>2.5 BHK apartments in Kollur starting at</h6>
                                    <h6>just 60 Lakhs</h6>
                                </>
                                :
                                <>
                                    <h1>Introducing M3, Your First Step to Fun Living in Kollur!</h1>
                                    <h6 className='text-white'>Fully furnished, smart home-automated 2.5 BHK apartments in Kollur starting at just 60 Lakhs</h6>
                                </>
                            :
                            <>
                                <h1>Introducing M3, </h1>
                                <h1>Your First Step to Fun Living in Kollur!</h1>
                                <h6 className='text-white'>Fully furnished, smart home-automated 2.5 BHK apartments in Kollur</h6><h6 className='mb-5 text-white'> starting at just 60 Lakhs</h6>
                            </>
                        }
                    </div>


                    <button type="submit" className='primary-background text-white common-btn mt-3 border-0 mb-4' onClick={() => dispatch(setToggleTrue())}>Get Ready to Own! </button>
                    {isMobile ?
                        isSmallMobile ?
                            <>
                                <h6 style={{ color: '#FFBA31', lineHeight: '7px' }} className='mt-4'>Book a site visit and get a sneak</h6>
                                <h6 style={{ color: '#FFBA31', lineHeight: '7px' }}>peek of your new home in Kollur!</h6>
                            </>
                            :
                            <h6 style={{ color: '#FFBA31' }} className='px-2 mt-4'>Book a site visit and get a sneak peek of your new home in Kollur!</h6>
                        :
                        <h6 style={{ color: '#FFBA31' }} className='px-2 mt-5'>Book a site visit and get a sneak peek of your new home in Kollur!</h6>
                    }

                </div>
            </Container>
        </div>
    )
}

export default Banner
