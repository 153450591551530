import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import bg from '../images/bg2.webp'
import img1 from '../images/location.webp'
import img2 from '../images/location2.webp'
import bg2 from '../images/sphere_small.webp'
import { useMediaQuery } from 'react-responsive';
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch, useSelector } from 'react-redux';

const Location = () => {
  const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className={`location-section position-relative ${isMobile ? 'section-padding' : 'py-6'}`}>
      <Container>
        <Row>
            <Col lg={7} md={12} xs={12} className='d-flex align-items-center'>
                <div className={`${isMobile ? 'mb-4' : ''}`}>
                    <h2 className={`text-capitalize text-white ${isMobile ? 'mb-3' : 'mb-5'} `}>Live in Kollur – The Next Big Residential Hub in Hyderabad</h2>
                    <h6>A perfect location for work-life balance, offering easy access to Hyderabad’s Financial District, Gachibowli, and top schools and hospitals.</h6>
                    {!isMobile &&
                    <button type="submit" className='primary-background text-white common-btn mt-3 border-0' onClick={() => dispatch(setToggleTrue())}>See Location </button>
                    }
                </div>
            </Col>
            <Col lg={5} md={12} xs={12} >
                <Row className='mb-3'>
                    <img src={img1} alt="" className='img-fluid'></img>
                </Row>
                <Row>
                    <img src={img2} alt="" className='img-fluid'></img>
                </Row>
            </Col>
        </Row>
        {isMobile && 
        <button type="submit" className='primary-background text-white common-btn mt-3 border-0 mb-4' onClick={() => dispatch(setToggleTrue())}>See Location </button>
        }
      </Container>
      <img src={isMobile ? bg2 :bg} alt="" className='img-fluid position-absolute sphere-img'></img>
    </div>
  )
}

export default Location
