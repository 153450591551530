import { configureStore } from '@reduxjs/toolkit'
import toggleReducer from './slice/changeState'
// import masterReducer from './Slice/master'

const store = configureStore({
    reducer: {
        toggle: toggleReducer,
        // master : masterReducer,
    },
  })
  
  export default store;