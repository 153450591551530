import React from 'react'
import { Container } from 'react-bootstrap'
import bg from '../images/bg2.webp'
import bg2 from '../images/Ellipse.webp'
import img1 from '../images/ikea_desktop.webp'
import img2 from '../images/ikea_mob.webp'
import { useMediaQuery } from 'react-responsive';

const Ikea = () => {
  const isMobile = useMediaQuery({ query: '(min-width: 376px) and (max-width: 1200px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 375px)' });
  const viewPort = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className={`ikea-section position-relative ${isMobile ? 'section-padding' : 'py-6'}`}>
      <img src={bg2} alt="ecllipse" className='position-absolute top-0 end-0 img-fluid'></img>
      <Container>
        <div className='text-center mb-4'>
          {
            isSmallMobile ? 
            <>
              <h2>Ready to Move In?</h2>
              <h2>How About We Throw</h2>
              <h2>In <span>5,55,555 INR</span> Worth</h2>  
              <h2>of IKEA Furnishings?</h2>
            </>
            :
            isMobile ?
            <h2>Ready to Move In? How About We Throw In <span>5,55,555 INR</span> Worth of IKEA Furnishings?</h2>
            :
            <>
              <h2>Ready to Move In? How About We Throw</h2>
              <h2>In <span>5,55,555 INR</span> Worth of IKEA</h2>
              <h2>Furnishings?</h2>
            </>
          }

          
        </div>
        <div>
          <h6>Yes, you heard that right! Buy a 2.5 BHK at M3 and get a furnishing package worth 5,55,555 </h6>
          <h6>INR from IKEA to make your new home as stylish as you are. But hurry, this offer is only </h6>
          <h6>valid until Dec 1st, 2024!</h6>
        </div>

        <div className='d-flex justify-content-center mt-5'>
          {/* {
            viewPort ?
            <img src={img2} alt="" className='img-fluid'></img>
            :
<img src={img1} alt="" className='img-fluid'></img>
          } */}
         
         <img src={viewPort ? img2 : img1} alt="" className='img-fluid'></img>

        </div>
      


        <div className='text-center mt-5'>
          <button type="submit" className='primary-background text-white common-btn mt-3 border-0 mb-4' >Claim Your IKEA Furnishing!</button>
        </div>
      </Container>
      <img src={bg} alt="ecllipse" className='position-absolute bottom-0 end-0 img-fluid'></img>
    </div>
  )
}

export default Ikea
