import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ContactForm from './ContactForm'
import bg from '../images/bg1.webp'
import { useMediaQuery } from 'react-responsive';

const Contact = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className='contact-section position-relative'>
            <Container className=''>
                <Row>
                    <Col lg={5} md={12} xs={12}>
                        <div className='px-3' style={{marginBottom: isMobile ? '14%' : ''}}>
                            <h2 className='mb-4 text-capitalize'>Ready to make the fun move?</h2>
                            <h6>Owning your first home is exciting—let us help you get there! Fill in your details, and our friendly team will guide you through the process and show you why M3 is the perfect match for first-time buyers like you.</h6>
                        </div>
                        <img src={bg} alt="background" className='img-fluid ' ></img>
                    </Col>
                    <Col lg={7} md={12} xs={12}>
                        <div className='contact-form position-relative'>
                            <ContactForm />
                        </div>
                    </Col>
                </Row>
                
            </Container>
            {!isMobile && 
            <img src={bg} alt="background" className='img-fluid ' ></img>
        }
        </div>
    )
}

export default Contact
