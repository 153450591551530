import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import img1 from '../images/east.webp'
import img2 from '../images/west.webp'
import bg1 from '../images/facing_bg.webp'
import bg4 from '../images/facing_bg_small.webp'
import bg2 from '../images/facing_line.webp'
import bg3 from '../images/line_small.webp'
import img3 from '../images/east_label.webp'
import img4 from '../images/west_label.webp'
import { useMediaQuery } from 'react-responsive';

const Facing = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className={`facing-section position-relative  ${isMobile ? 'section-padding' : 'py-6'}`} id="facing">
            <Container>
                <Row>
                    <Col lg={7} md={12} xs={12} className='d-flex align-items-center'>
                        <div>
                            <h2 className='text-capitalize mb-5 primary-color'>Smartly Designed 2.5 BHK Apartments for Modern Living</h2>
                            <h6 className='mb-4'>Explore the thoughtfully designed layouts of our 1105 Sq. Ft. 2.5 BHK apartments, perfect for families and working professionals.</h6>
                            <p className='mb-5'>IRA M3 offers spacious 2.5 BHK apartments with intelligent design that maximizes space and functionality. These units are ideal for families looking for a perfect blend of comfort and smart living.</p>
                            {!isMobile &&
                            <button type="submit" className='primary-background text-white common-btn mt-3 border-0 mb-4'>Download Brochure</button>
                            }
                        </div>
                    </Col>
                    <Col lg={5} md={12} xs={12}>
                        <Row className='mb-3'>
                            <Card className='border-0 d-flex'>
                                {/* <img src={img3} alt="east-facing" className=''></img> */}
                                <img src={img1} alt="east-facing" className='img-fluid'></img>
                            </Card>
                        </Row>
                        <Row >
                            <Card className='border-0'>
                                <img src={img2} alt="west-facing" className='img-fluid'></img>
                            </Card>
                        </Row>
                    </Col>
                </Row>
                {isMobile && 
                    <button type="submit" className='primary-background text-white common-btn mt-3 border-0 mb-4' >See Location </button>
                }
            </Container>
            <img src={isMobile ? bg4 : bg1} alt="background" className='img-fluid facing-bg'></img>
            
            <img src={isMobile ? bg3 : bg2} alt="background-line" className='img-fluid facing-line'></img>
        </div>
    )
}

export default Facing
